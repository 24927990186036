import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ConfirmationForm, InvestmentForm, ProductSelector, StyledContactForm, StyledFooter } from '@/components';
import StyledNavigationBar from '@/components/NavigationBar';
import { ProductKind } from '@/interfaces';
import Layout from '@/styles';

const InvestmentContainer = styled.div`
  padding-top: 140px;
  padding-bottom: 100px;

  @media (max-width: 991px) {
    padding-bottom: 0;
    padding-top: 0;
  }
`;

const Investment = (): JSX.Element => {
  const [choosenProduct, setChoosenProduct] = useState<ProductKind>();
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{ t('common:page-titles.investment') }</title>
      </Helmet>
      <Layout>
        <StyledNavigationBar
          className="navbar"
          darkMenuIcon
          initialValue="NONE"
        />
        <InvestmentContainer>
          <ProductSelector onProductSelect={(product): void => setChoosenProduct(product)} />
          {
            choosenProduct
              ? (
                <div id="investment-form-container">
                  {
                    choosenProduct.isAvailable
                      ? <InvestmentForm productKind={choosenProduct} />
                      : (
                        <ConfirmationForm
                          productKind={choosenProduct}
                          subTitle="investmentForm:leave-your-contact"
                          title="investmentForm:available-soon"
                        />
                      )
                  }
                </div>
              )
              : <></>
          }
        </InvestmentContainer>
        <StyledContactForm id="contact" />
        <StyledFooter />
      </Layout>
    </>
  );
};

export default Investment;
